<template>
  <div>
    <ActionList
      :list-items="orders"
      :filters="filters"
      :requires-remote-filtering="true"
      :fixed-filters="fixedFilters"
      :sort-list-items-callback="sortOrders"
      :load-items-callback="loadItemsCallback"
      :empty-text="$t('noOrdersFound')"
      v-on="$listeners"
      db-table="orders"
      :actions="actions"
      :loading="loading"
      :preselected-filter="preselectedFilter"
    >
      <template v-slot:item="{ item, index }">
        <OrderListItem
          v-if="item !== null"
          :key="item.uuid"
          :order="item"
          @select-order="$emit('select-order', item)"
        />
        <v-divider
          inset
          :key="index"
        />
      </template>
    </ActionList>
    <div v-intersect.quiet="loadMore" />
  </div>
</template>

<script>

  import OrderListItem from "../components/OrderListItem"
  import ActionList from "./common/list/ActionList"

  export default {
    name: 'OrderList',
    props: {
      orders: {
        type: Array,
        default: () => []
      },
      length: {
        type: Number,
        default: 100
      },
      step: {
        type: Number,
        default: 100
      },
      loadItemsCallback: {
        type: Function,
        default: () => null
      },
      fixedFilters: {
        type: Array,
        default: () => []
      },
      loading: {
        type: Boolean,
        default: () => false
      }
    },
    components: {
      OrderListItem,
      ActionList
    },
	  emits: ['select-order'],
    data() {
      return {
        startIndex: 0,
        isLoadingMore: false,
        listLength: 0,
        preselectedFilter: {
          key: 'accountingTime',
          label: this.$t('dateCreated'),
          type: 'datetime',
          value: [
            Date.now() - 86400000, // One day in millis
            Date.now() 
          ],
          operator: 'between'
        }
      }
    },
    mounted(){
      this.listLength = this.length
		this.$store.dispatch('getCoupons')
    },
    computed: {
		orderOrigins() {
			return [
				{
					text: 'Kiosk',
					value: 'kiosk'
				},
				{
					text: 'Etags',
					value: 'etags'
				},
				{
					text: 'Store',
					value: 'store'
				},
				{
					text: 'App',
					value: 'app'
				}
			]
		},
      slicedOrders() {
        return this.orders.slice(0, this.listLength)
      },
      actions() {
        return this.$store.getters.bulkOperationsByRoute('sales/orders')
      },
		channels() {
		  // TODO: Need to be able to find orders created in back-office
			const channels = this.$store.state.channels
			if(Array.isArray(channels)) {
				return this.$store.state.channels.reduce((arr, channel) => {
					if(channel) {
						arr.push({
							text: channel.label,
							value: channel.uuid
						})
					}
					return arr
				}, []).concat([{ text: this.$t('Back-office'), value: null }])
			}
			return []
		},
		locationVariations() {
			const locations = this.$store.state.locations
			if(!Array.isArray(locations)) {
				return []
			}
			let locationVariations = []
			for(let locationIndex = 0; locationIndex < locations.length; locationIndex++) {
				const location = locations[locationIndex]
				if(!location) {
					continue
				}
				if(!Array.isArray(location.variations) || location.variations.length <= 0) {
					continue
				}
				locationVariations = locationVariations.concat([{ header: location.label }], location.variations)
			}
			locationVariations.unshift({ label: 'None', uuid: null })
			return locationVariations.reduce((arr, variation) => {
				if(variation) {
					arr.push({
						text: variation.label,
						value: variation.uuid ? variation.uuid.toString() : null
					})
				}
				return arr
			}, [])
		},
		couponOptions() {
			return [
				{
					text: this.$t('No coupon'),
					value: null
				},
				{
					text: this.$t('Has coupon'),
					value: 'notNull'
				}
			]
		},
		coupons() {
			const coupons = this.$store.state.coupons
			if(!Array.isArray(coupons)) {
				return []
			}
			return coupons.reduce((arr, coupon) => {
				if(coupon) {
					arr.push({
						text: coupon.label,
						code: coupon.code,
						value: coupon.uuid
					})
				}
				return arr
			}, [])
		},
	    filters() {
		  return [
			  {
				  key: 'accountingTime',
				  label: this.$t('Created'),
				  type: 'datetime',
				  value: [
					  0,
					  0
				  ],
				  operator: 'between'
			  },
			  {
				  key: 'totalAmount.amount',
				  label: this.$t('Total Amount'),
				  type: 'number',
				  value: null,
				  operator: 'equals'
			  },
			  {
				  key: 'orderId',
				  label: this.$t('Order Id'),
				  type: 'text',
				  value: null,
				  operator: 'equals'
			  },
			  {
				  key: 'uuid',
				  label: this.$t('Order UUID'),
				  type: 'text',
				  value: null,
				  operator: 'contains'
			  },
			  {
				  key: 'channel.uuid',
				  label: this.$t('Channel'),
				  type: 'autocomplete',
				  value: '',
				  items: this.channels,
				  operator: 'contains'
			  },
			  {
				  key: 'customer.address.name_line',
				  label: this.$t('Customer Name'),
				  type: 'text',
				  value: null,
				  operator: 'contains'
			  },
			  {
				  key: 'customer.phone',
				  label: this.$t('Customer Phone'),
				  type: 'text',
				  value: null,
				  operator: 'contains'
			  },
			  {
				  key: 'customer.email',
				  label: this.$t('Customer Email'),
				  type: 'text',
				  value: null,
				  operator: 'contains'
			  },
			  {
				  key: 'customer.ssn',
				  label: this.$t('Customer SSN'),
				  type: 'text',
				  value: null,
				  operator: 'contains'
			  },
			  {
				  key: 'locationVariation.uuid',
				  label: this.$t('Table/Unit'),
				  type: 'autocomplete',
				  value: '',
				  operator: 'contains',
				  items: this.locationVariations
			  },
			  {
				  key: 'balance.amount',
				  label: this.$t('Balance Amount'),
				  type: 'number',
				  value: null,
				  operator: 'equals'
			  },
			  {
				  key: 'paid.amount',
				  label: this.$t('Paid Amount'),
				  type: 'number',
				  value: null,
				  operator: 'equals'
			  },
			  {
				  key: 'coupon.uuid',
				  label: this.$t('Coupon'),
				  type: 'autocomplete',
				  value: '',
				  operator: 'contains',
				  items: this.coupons
			  },
			  {
				  key: 'sequence',
				  label: this.$t('Sequence'),
				  type: 'text',
				  value: null,
				  operator: 'contains'
			  },
			  {
				  key: 'origin',
				  label: this.$t('Origin'),
				  type: 'autocomplete',
				  value: '',
				  operator: 'contains',
				  items: this.orderOrigins

			  }
			  // {
				//   key: 'couponCalculation',
				//   label: this.$t('Coupon on Order'),
				//   type: 'select',
				//   value: '',
				//   operator: 'equals',
				//   items: this.couponOptions
			  // }
			  // Boolean filter for order or line item having applied coupon
			  // Orders with no customer set
			  // date and time (before, after, exactly)
			  // amounts (higher or equal to, lower or equal to)
			  // Locations
			  // Tags
			  // Fulfillment [ TakeAway, takeAway, Delivery, EatIn, eatIn ]
			  // Origin [ kiosk, etags, epos, admin, foodora, wolt, bolt, karma, ubereat, deliverect, deliveroo, delivery_hero, food_panda, glovo, takeaway_com, just_eat, skip_the_dishes, flyt, doordash, ritual, zomato, eatch, flipdish, grab, talabat, schedule, item, store, yess, app ]
			  // TODO: Make filters work with currency
			  // TODO: Make filters work with null vs not null values, as in has value or does not have value
		  ]
	    }
    },
    methods: {
      sortOrders(c1, c2) {
        return c1.created < c2.created
      },
      loadMore() {
        this.isLoadingMore = true
        this.listLength = this.listLength + this.step
      }
    }
  }
</script>
